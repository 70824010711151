import * as React from 'react'
import Layout from '../../components/Navigation/layout'
import '../../theme/main.module.scss';
import Element from "../../components/Element/Element";
import {useEffect, useState} from "react";
import IndexPage from "../../pages/landingpage";


const ContentPage = ({pageContext: {page}}) => {

    const [isLandingPage, setIsLandingPage] = useState(false);

    useEffect(() => {
        if(window.location.href.includes('/s/wer-kann-haendler-werden')) {
            //window.location.href = 'https://fairstaerkt.at/landingpage/';
            setIsLandingPage(true);
        }
    }, [window.location])

    return (
        <React.StrictMode>
            {!isLandingPage ?  <Layout pageTitle={page.MetaTitle ?? page.Title ?? 'fairstärkt'}
                    description={page.MetaDescription ?? ''}
                    image={page.MetaImageLink ?? ''}
            >
                {console.log(page)}
                {console.log(page.Elements)}
                {page.Elements && page.Elements.map((data: any, index) => <Element key={index} data={data} pageTitle={page.MetaTitle ?? page.Title ?? 'fairstärkt'}/>)}
            </Layout> : <IndexPage />}
        </React.StrictMode>
    )
}

export default ContentPage;
